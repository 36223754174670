import React from 'react'
// import SvgSportGradient from 'src/components/tools/svgSportGradient'

const SvgBack = () => {

  return (
    <div
      id="svgContainer"
      className={`svgWrap svgContainer`}
    >
      <div 
        className={`svgWrapInner`} 
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          viewBox={`0 1 1116.213 1087.989`}
        >

          {/* <defs>
            <SvgSportGradient />
          </defs> */}

          <path
            id="Path_7165"
            d="M-.856,83.57l626.495-1.033L930.357,1167-.076,1170.525Z"
            transform="translate(0.856 -82.536)"

            fill="#0c0c0d"            
          />
        </svg>
      </div>
    </div>
  )
}
export default SvgBack
