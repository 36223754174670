import React from 'react'

function SvgBusinessGradient() {

  return (
    <>

        <radialGradient 
            id="svgBusinessBackground" 
            cx="0.444" 
            cy="0.333" 
            r="1.333"

            fx="0.55" 
            fy="0.33" 
        >

            <stop 
                offset="0" 
                stopColor="#131316" 
                stopOpacity="1"
            >

                <animate id="gradnimeZ21xx"
                    // attributeType="XML"
                    attributeName="stopOpacity"
                    dur="1900ms"
                    begin="500ms"
                    calcMode="linear"
                    values="0 ; 0.2 ; 0"
                    keyTimes="0 ; 0.2 ; 1"
                    fill="freeze"
                />

            </stop>

            <stop 
                offset="0.083" 
                stopColor="#1a1a1f"
                stopOpacity="0.85"
            >

                <animate id="opameZ2"
                    // attributeType="XML"
                    attributeName="stopOpacity"
                    dur="1500ms"
                    begin="900ms"
                    calcMode="linear"
                    values="0.85 ; 0.3 ; 0.95"
                    keyTimes="0 ; 0.13 ; 1"
                    fill="freeze"
                />  

                <animate id="gradnimeZ1xx"
                    // attributeType="XML"
                    attributeName="offset"
                    dur="4650ms"
                    begin="285ms"
                    calcMode="linear"
                    values="0.083 ; 0.093 ; 0"
                    keyTimes="0 ; 0.2 ; 1"
                    fill="freeze"
                />

            </stop>

            <stop 
                offset="0.11" 
                stopColor="#1f1f24" 
                stopOpacity="0.66"
            >

                <animate id="opame2"
                    // attributeType="XML"
                    attributeName="stopOpacity"
                    dur="1600ms"
                    begin="300ms"
                    calcMode="linear"
                    values="0.66 ; 0.88 ; 0.66"
                    keyTimes="0 ; 0.23 ; 1"
                    fill="freeze"
                />  

                <animate id="gradnime1xx"
                    // attributeType="XML"
                    attributeName="offset"
                    dur="8400ms"
                    begin="800ms"
                    calcMode="linear"
                    values="0.11 ; 0.315 ; 0.11"
                    keyTimes="0 ; 0.54 ; 1"
                    fill="freeze"
                />  

                <animate id="gradnime12xxx"
                    attributeName="offset"
                    dur="2300ms" 
                    begin="gradnime1xx.end"
                    from="0.11"
                    to="0"
                    fill="freeze"
                />

            </stop>

            <stop 
                offset="0.66" 
                stopColor="#0c0c0d" 
                stopOpacity="1"
            >

                <animate id="opame4"
                    // attributeType="XML"
                    attributeName="stopOpacity"
                    dur="2000ms"
                    begin="200ms"
                    calcMode="linear"
                    values="1 ; 0.92 ; 1"
                    keyTimes="0 ; 0.64 ; 1"
                    fill="freeze"
                />  

                <animate id="gradnime11B"
                    // attributeType="XML"
                    attributeName="offset"
                    dur="4400ms"
                    begin="0ms"
                    calcMode="linear"
                    values="0.66 ; 0.55 ; 0.33"
                    keyTimes="0 ; 0.26 ; 1"
                    fill="freeze"
                />  

                <animate id="gradnime11xx"
                    attributeName="offset"
                    dur="5500ms" 
                    begin="gradnime1.end"
                    from="0.33"
                    to="0.66"
                    fill="freeze"
                />

            </stop>

        </radialGradient>




        {/* <radialGradient 
            id="svgBusinessBackgroundSPEED" 
            cx="0.444" 
            cy="0.333" 
            r="1.333"
            fx="0.55" 
            fy="0.33" 
        >

            <stop 
                offset="0" 
                stopColor="#1f1f24" 
                stopOpacity="1"
            >

                <animate id="gradnimeZ21xx"
                    // attributeType="XML"
                    attributeName="stopOpacity"
                    dur="950ms"
                    begin="250ms"
                    calcMode="linear"
                    values="0 ; 0.103 ; 0"
                    keyTimes="0 ; 0.19 ; 1"
                    fill="freeze"
                />

            </stop>

            <stop 
                offset="0.083"
                stopColor="#1f1f23" 
                stopOpacity="0.85"
            >

                <animate id="opameZ2"
                    // attributeType="XML"
                    attributeName="stopOpacity"
                    dur="750ms"
                    begin="450ms"
                    calcMode="linear"
                    values="0.85 ; 0.3 ; 0.95"
                    keyTimes="0 ; 0.13 ; 1"
                    fill="freeze"
                />  

                <animate id="gradnimeZ1xx"
                    // attributeType="XML"
                    attributeName="offset"
                    dur="2325ms"
                    begin="142ms"
                    calcMode="linear"
                    values="0.083 ; 0.093 ; 0"
                    keyTimes="0 ; 0.2 ; 1"
                    fill="freeze"
                />

            </stop>

            <stop 
                offset="0.11" 
                stopColor="#1f1f24" 
                stopOpacity="0.95"
            >

                <animate id="opame2"
                    // attributeType="XML"
                    attributeName="stopOpacity"
                    dur="800ms"
                    begin="150ms"
                    calcMode="linear"
                    values="0.95 ; 0.8 ; 0.95"
                    keyTimes="0 ; 0.23 ; 1"
                    fill="freeze"
                />  

                <animate id="gradnime1xx"
                    // attributeType="XML"
                    attributeName="offset"
                    dur="2200ms"
                    begin="400ms"
                    calcMode="linear"
                    values="0.11 ; 0.315 ; 0.11"
                    keyTimes="0 ; 0.54 ; 1"
                    fill="freeze"
                />  

                <animate id="gradnime12xxx"
                    attributeName="offset"
                    dur="1150ms" 
                    begin="gradnime1xx.end"
                    from="0.11"
                    to="0"
                    fill="freeze"
                />

            </stop>

            <stop 
                offset="0.66" 
                stopColor="#0c0c0d" 
                stopOpacity="1"
            >

                <animate id="opame4"
                    // attributeType="XML"
                    attributeName="stopOpacity"
                    dur="1000ms"
                    begin="100ms"
                    calcMode="linear"
                    values="1 ; 0.92 ; 1"
                    keyTimes="0 ; 0.64 ; 1"
                    fill="freeze"
                />  

                <animate id="gradnime11B"
                    // attributeType="XML"
                    attributeName="offset"
                    dur="2200ms"
                    begin="0ms"
                    calcMode="linear"
                    values="0.66 ; 0.55 ; 0.33"
                    keyTimes="0 ; 0.26 ; 1"
                    fill="freeze"
                />  

                <animate id="gradnime11xx"
                    attributeName="offset"
                    dur="2250ms" 
                    begin="gradnime1.end"
                    from="0.33"
                    to="0.66"
                    fill="freeze"
                />

            </stop>

        </radialGradient> */}

    </>
  )
}
export default SvgBusinessGradient