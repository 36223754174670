import React from 'react'


// const SvgBack = data => {
const SvgBackStatic = () => {
  // const videoPlay = data.play === false ? `` : `videoPlay`

  return (
    <div
      id="svgContainer2"
      className={`svgWrap svgContainer`}
    >
      <div 
        className={`svgWrapInner`} 

        style={{ height: '100%' }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          // viewBox={`0 ${vY} 1116.213 1087.989`}
          // viewBox={`0 1 1116.213 1087.989`}

          fill={`url(#svgBusinessBackgroundStatic)`}
        >

          <defs>
            
            <radialGradient 
              id="svgBusinessBackgroundStatic" 
              cx="0.444" 
              cy="0.333" 
              r="1.333"
              // fr="0.133"
              fx="0.55" 
              fy="0.33" 
              // gradientUnits="userSpaceOnUse" 
              // spreadMethod="reflect"
            >

              <stop 
                  offset="0" 

                  // stopColor="#ff4a4a" 
                  // stopColor="#D4AB4A" 

                  // stopColor="#000" 
                  // stopColor="#0c0c0d"

                  stopColor="#1f1f24" 

                  stopOpacity="1"
              >
              </stop>


              <stop 
                  offset="0" 

                  // stopColor="#ff4a4a" 
                  // stopColor="#D4AB4A" 

                  // stopColor="#000" 
                  // stopColor="#0c0c0d"

                  stopColor="#1f1f24" 

                  stopOpacity="0.75"
              >
              </stop>


              <stop 
                  offset="0.11" 

                  // stopColor="#ff4a4a" 
                  // stopColor="#26262b" 
                  stopColor="#1f1f24" 
                  // stopColor="#000" 

                  stopOpacity="0.98"
              >
              </stop>


              <stop 
                  offset="0.66" 

                  // stopColor="#990000" 
                  // stopColor="#0e0e12" 
                  stopColor="#0c0c0d" 

                  stopOpacity="1"
              >
              </stop>

            </radialGradient>
            
          </defs>

          <rect
            // id=""
            width={`100%`}
            height={`100%`}
          />

        </svg>
      </div>
    </div>
  )
}
export default SvgBackStatic
