import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import SwiperCore, { Autoplay, EffectFlip, Controller } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

SwiperCore.use([ Autoplay, EffectFlip, Controller ])

const Clients = () => {
  const clientLogos = useStaticQuery(graphql`
    query BusinessClients {
      allWpCptBusinessClient {
        edges {
          node {
            ACFbusinessclients_layout {
              clientLogoBusinessClientsLogosLayout {
                altText
                description
                title
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 140, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const logos = clientLogos.allWpCptBusinessClient.edges
  
  const logosG1 = logos.slice(0, (logos.length / 4))
  const logosG2 = logos.slice((logos.length / 4), ( 2 * (logos.length / 4)))
  const logosG3 = logos.slice(( 2 * (logos.length / 4)), ( 3 * (logos.length / 4)))
  const logosG4 = logos.slice(( 3 * (logos.length / 4)))

  const[firstSwiper, setFirstSwiper] = useState(null)
  const[secondSwiper, setSecondSwiper] = useState(null)
  const[thirdSwiper, setThirdSwiper] = useState(null)
  const[fourthSwiper, setFourthSwiper] = useState(null)

  return (
    <div
      className={'aboutus-bg clientsSlider'}
      style={{
        position: 'fixed',
        zIndex: '1',
      }}
    >
          <Swiper
            observer={true}
            observeParents={true}
            rebuildOnUpdate={true}
            direction="vertical"
            effect="flip"
            loop={true}
            // autoPlay={{
            autoplay={{
              autoplay: true,
              delay: 1666 }}
            speed={999}
            onSwiper={() => setTimeout(setSecondSwiper, 1000)}
            controller={{ control: firstSwiper }}
            style={{height: '120px'}}
            className={`logosG1`}
          >
            {logosG1 && logosG1.map((logo, key) => {
              return(
                <SwiperSlide key={key}>
                  <Image
                    fluid={logo.node.ACFbusinessclients_layout.clientLogoBusinessClientsLogosLayout.localFile.childImageSharp.fluid}
                    style={{width:'140px'}}
                    alt={logo.node.ACFbusinessclients_layout.clientLogoBusinessClientsLogosLayout.altText}
                    title={logo.node.ACFbusinessclients_layout.clientLogoBusinessClientsLogosLayout.title}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>

          <Swiper
            observer={true}
            observeParents={true}
            rebuildOnUpdate={true}
            direction="vertical"
            effect="flip"
            loop={true}
            // autoPlay={{
            autoplay={{
              delay: 1666 }}
            speed={999}
            onSwiper={() => setTimeout(setThirdSwiper, 1000)}
            controller={{ control: secondSwiper }}
            style={{height: '120px'}}
            className={`logosG2`}
          >
            {logosG2 && logosG2.map((logo, key) => {
              return(
                <SwiperSlide key={key}>
                  <Image
                    fluid={logo.node.ACFbusinessclients_layout.clientLogoBusinessClientsLogosLayout.localFile.childImageSharp.fluid}
                    style={{width:'140px'}}
                    alt={logo.node.ACFbusinessclients_layout.clientLogoBusinessClientsLogosLayout.altText}
                    title={logo.node.ACFbusinessclients_layout.clientLogoBusinessClientsLogosLayout.title}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>

          <Swiper
            observer={true}
            observeParents={true}
            rebuildOnUpdate={true}
            direction="vertical"
            effect="flip"
            loop={true}
            // autoPlay={{
            autoplay={{
              delay: 1666 }}
            speed={999}
            onSwiper={() => setTimeout(setFourthSwiper, 1000)}
            controller={{ control: thirdSwiper }}
            style={{height: '120px'}}
            className={`logosG3`}
          >
            {logosG3 && logosG3.map((logo, key) => {
              return(
                <SwiperSlide key={key}>
                  <Image
                    fluid={logo.node.ACFbusinessclients_layout.clientLogoBusinessClientsLogosLayout.localFile.childImageSharp.fluid}
                    style={{width:'140px'}}
                    alt={logo.node.ACFbusinessclients_layout.clientLogoBusinessClientsLogosLayout.altText}
                    title={logo.node.ACFbusinessclients_layout.clientLogoBusinessClientsLogosLayout.title}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>

          <Swiper
            observer={true}
            observeParents={true}
            rebuildOnUpdate={true}
            direction="vertical"
            effect="flip"
            loop={true}
            // autoPlay={{
            autoplay={{
              delay: 1666 }}
            speed={999}
            onSwiper={() => setTimeout(setFirstSwiper, 1000)}
            controller={{ control: fourthSwiper }}
            style={{height: '140px'}}
            className={`logosG4`}
          >
            {logosG4 && logosG4.map((logo, key) => {
              return(
                <SwiperSlide key={key}>
                  <Image
                    fluid={logo.node.ACFbusinessclients_layout.clientLogoBusinessClientsLogosLayout.localFile.childImageSharp.fluid}
                    style={{width:'140px'}}
                    alt={logo.node.ACFbusinessclients_layout.clientLogoBusinessClientsLogosLayout.altText}
                    title={logo.node.ACFbusinessclients_layout.clientLogoBusinessClientsLogosLayout.title}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
    </div>
  )
}
export default Clients