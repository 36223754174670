import React, { useContext } from 'react'
// import { useStaticQuery, graphql } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from 'src/context/GlobalContextProvider'

const StartLayout = ({ page }) => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  // const graphqlResult = useStaticQuery(graphql`
  //   query BusinessStartPage_Layout {
  //     start: wpCptBusinessPage(AcfGatsbyId: {pageid: {eq: "start"}}) {      
  //       acfbusinesspagestart {
  //         subheader2BusinessPageStart
  //         subheaderBusinessPageStart
  //         ctaTextBusinessPageStart
  //         ctaLinkBusinessPageStart
  //       }
  //     }
  //   }
  // `)

  /*    VIDEO HANDLLING   */
  function playVideo() {
    dispatch({ type: 'PLAY_START_BUSINESS' })
  }
  const hasPlayerStop = state.playStartBusiness ? playVideo : undefined
  // const portalDeep = state.playStartBusiness ? 'uberTop' : 'bottom'
  /*    END VIDEO HANDLLING   */

  let containerClass = state.playStartBusiness ? "videoPlaying" : "videoStoped"


  return (
    <>
      {state.playStartBusiness && <>
        <div
          className={`videoContainer ${containerClass}`}
        >
          <div className={`videoContainerInner`}>
              <>
                <iframe 
                  title="modernforms promo"
                  width={`${state.windowWidth < 1280 ? state.windowWidth - 24 : 1280}`}
                  height={`${state.windowWidth < 1280 ? state.windowWidth * 0.5625 - 24: 720}`}
                  src={page.acfbusinesspagestart_layout.videolongBusinessPageStartLayout}
                  frameBorder="0"
                  allowFullScreen
                  allow="
                    accelerometer;
                    autoplay;
                    clipboard-write;
                    encrypted-media;
                    gyroscope;
                    picture-in-picture
                  "
                >This browser does not support iframe video</iframe>
              </>
          </div>
        </div>
        <div className={`videoControl`} onClick={hasPlayerStop} role="button" tabIndex="0" onKeyDown={hasPlayerStop} aria-label="forward button">
          <span
            className={`closeVideo`}
            >
            <FontAwesomeIcon icon={faTimes}/>
          </span>
        </div>
          </>}

      {(!state.playStartBusiness && state.windowWidth > 1078) &&
        <div 
          className={`videoLoop ${containerClass}`} 
          // style={{right: '34px',top:'100px'}}


          style={{right: '100px',top:'132px'}}

          // style={{right: `${}`,top:'100px'}}
        >
          <video
            // width={`${(state.windowWidth > 1320) ? 1240 : (state.windowWidth > 748) ? state.windowWidth - 80 : state.windowWidth}`}
            // width={`${(state.windowWidth > 1320) ? 1180 : (state.windowWidth > 1078) ? 1050 : (state.windowWidth > 748) ? state.windowWidth - 80 : state.windowWidth}`}
            // width={`${(state.windowWidth > 1078) ? state.windowWidth - 64 : state.windowWidth}`}
            
            
            // width={`${(state.windowWidth > 1078) ? state.windowWidth - 240 : state.windowWidth}`} 
            // width='auto'

            // height={`${(state.windowWidth > 1320) ? 720 : (state.windowWidth > 748) ? state.windowWidth * 0.5625 - 80: state.windowWidth * 0.5625}`}
            // height='auto'
            // height={`${state.windowWidth * 0.5625 - 208}`}



            width={`${state.windowMode === 'higher' ? 'auto' : ((state.windowHeight - 200) * 1.7778)-33 }`}

            height={`${state.windowMode === 'higher' ? ((state.windowWidth - 200) * 0.5625)-33 : 'auto' }`}



            autoPlay
            loop
            muted
            playsInline
          >
            <source src={page.acfbusinesspagestart_layout.videoshortBusinessPageStartLayout.localFile.publicURL} type="video/mp4" />
          </video>
        </div>
      }
    </>
  )
}
export default StartLayout