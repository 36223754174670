import React from 'react'
import SvgBusinessGradient from 'src/components/tools/svgBusinessGradient'

// const SvgBack = data => {
const SvgBack = () => {
  // const videoPlay = data.play === false ? `` : `videoPlay`

  return (
    <div
      id="svgContainer2"
      className={`svgWrap svgContainer`}
    >
      <div 
        className={`svgWrapInner`} 

        style={{ height: '100%' }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100%"
          height="100%"
          fill={`url(#svgBusinessBackground)`}
        >

          <defs>
            <SvgBusinessGradient />
          </defs>

          <rect
            width={`100%`}
            height={`100%`}
          />

        </svg>
      </div>
    </div>
  )
}
export default SvgBack
