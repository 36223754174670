import React, { useState, useEffect, useLayoutEffect, useContext, useRef } from 'react'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
// import { TransitionState } from 'gatsby-plugin-transition-link';
// import InterSectionTransition from 'src/components/layouts/interSectionTransition'
// import TransitionCamo from 'src/components/layouts/transitionCamo'

import Image from 'gatsby-image'
import { globalHistory } from "@reach/router"
import { useStaticQuery, graphql } from 'gatsby'
import { useSwipeable } from 'react-swipeable'

import SvgPlay from 'src/images/playButton'
import SvgBack from 'src/components/business/svgBack'
import SvgBackMap from 'src/components/shippingData/svgBackMap'
import SvgSubBack from 'src/components/business/svgSubBack'
import SvgSubBackStatic from 'src/components/business/svgSubBackStatic'

import Header from 'src/components/common/header'
import Footer from 'src/components/common/footer'

import LayoutPortalTop from 'src/components/layouts/layoutPortalTop'
import LayoutPortalBottom from 'src/components/layouts/layoutPortalBottom'

import WindowDriver from 'src/components/tools/windowDriver'

import {
  GlobalStateContext,
  GlobalDispatchContext,
} from 'src/context/GlobalContextProvider'

import logo from '../../../static/logoWhite.svg'
import logoBlack from '../../../static/logoBlack.svg'

import OrderBackImg from 'src/images/ilustracja-zamowienie-biznes.svg'
import ReviewsBackImg from 'src/images/ilustracja-opinie-klientow-biznes.svg'
import ClientsBackgroundSlider from 'src/components/business/pages/clientsBackgroundSlider'
import StartLayout from 'src/components/business/pages/startBackgroundVideo.js'

import { navigate } from "gatsby"


const BusinessLayout = ({ children, pageContext, page }) => {
  const graphqlResult = useStaticQuery(graphql`
  query BusinessLayoutQuery {

    siteSlogan: wpCpTgeneral(AcfGatsbyId: {pageid: {eq: "business-slogan"}}) {
      acfgeneralinfos_layout {
        lineGenralInfosInfo
      }
    }

    headerMenu: allWpMenu(filter: { name: { eq: "business-mainmenu" } }) {
      edges {
        node {
          name
          id
          menuItems {
            nodes {
              label
              parentId
              childItems {
                nodes {
                  id
                }
              }
              path
              id
              cssClasses
              order
            }
          }
        }
      }
    }

    switchMenu: allWpMenu(filter: { name: { eq: "sectionSwitch" } }) {
      edges {
        node {
          name
          id
          menuItems {
            nodes {
              label
              parentId
              cssClasses
              childItems {
                nodes {
                  id
                }
              }
              path
              id
              cssClasses
            }
          }
        }
      }
    }
    privacyPolicy: wpPage(AcfGatsbyId: {pageid: {eq: "privacy-policy"}}) {
      slug
    }
  }
`)


  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  const siteSlogan = graphqlResult.siteSlogan.acfgeneralinfos_layout.lineGenralInfosInfo

  const sidewaysLinkPrev = useRef(null)
  const sidewaysLinkNext = useRef(null)

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Right' && sidewaysLinkPrev.current) sidewaysLinkPrev.current.click()
      else if (eventData.dir === 'Left' && sidewaysLinkNext.current) sidewaysLinkNext.current.click()
    },
    delta: 20
  })

  function orientationInitial() {
    return state.windowWidth > 1061 ? 'desktop' : 'mobile'
  }
  const[orientation, setOrientation] = useState(() => orientationInitial())
  useLayoutEffect(() => {
    setOrientation(state.windowWidth > 1061 ? 'desktop' : 'mobile')
  }, [state.windowWidth])

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        state.menu && dispatch({ type: 'TOGGLE_MENU' })
      }
    })
  }, [state.menu])  // eslint-disable-line react-hooks/exhaustive-deps

  
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (page.AcfGatsbyId.pageid === 'start' && action === 'POP' && state.playStartBusiness === true) {
        dispatch({ type: 'PLAY_START_BUSINESS' })
        navigate(`${pageContext.sitePath}`)
      }
    })
  }, [state.playStartBusiness])  // eslint-disable-line react-hooks/exhaustive-deps


  /*    VIDEO HANDLLING   */
  function playVideo() {
    dispatch({ type: 'PLAY_START_BUSINESS' })
  }
  const hasPlayer = page.AcfGatsbyId.pageid === 'start' ? playVideo : undefined

  const playHandlerRight = state.windowWidth > 1420 ? state.windowWidth / 2 - 330 : state.windowWidth - 1020


  return (
    <div className="wrapper" {...handlers} style={state.playStartBusiness ? {maxHeight:'100vh', zIndex:'0'} : {}}>

      {/* {<LayoutPortalTop 
        position={`relative`} 
        // position={`${orientation === 'desktop' ? 'fixed' : 'relative'}`}
        level={(state.playStartBusiness) ? 'bottom' : 'uberTop'}
      > */}
      {!(state.playStartBusiness) && <LayoutPortalTop 
        position={`relative`} 
        level={(state.playStartBusiness) ? 'bottom' : 'uberTop'}
        // level={state.offerProductMode ? '' : 'uberTop'}
      >
        <div className={`headingPortal`}>
          <div className={`container portalContainer`}>
            <div className="logo" style={state.offerProductMode ? {zIndex: '-100',position:'relative'} : {}}>
              <AniLink
                // to={`/${pageContext.realSlugs[pageContext.siteSlug]}`}
                to={`/`}
                duration={2}
                direction="right"
                swipe
                entryOffset={400}
              >
                <img src={logo} alt="Modern Forms logo" style={{width:'60px',display:'block'}}></img>
              </AniLink>
            </div>
            <span className="site-header-mark">
              {siteSlogan}
            </span>
          </div>
        </div>
        <Header pageContext={pageContext} pageid={page.AcfGatsbyId.pageid} headerMenu={graphqlResult.headerMenu} switchMenu={graphqlResult.switchMenu} sidewaysLinkPrev={sidewaysLinkPrev} sidewaysLinkNext={sidewaysLinkNext} />
      </LayoutPortalTop>}


      <LayoutPortalTop 
        level={state.playStartBusiness ? 'uberTop' : ''} 
        position={`${orientation === 'desktop' ? 'fixed' : 'relative'}`}
      >

        {(page.AcfGatsbyId.pageid === 'shipping' && state.windowWidth > 1061) ?
          <SvgBackMap /> : ''
        }

        {((page.AcfGatsbyId.pageid === 'clients' || page.AcfGatsbyId.pageid === 'placing-order') && orientation === 'mobile') &&
          <SvgBack />
        }

        {(page.AcfGatsbyId.pageid === 'start' || page.AcfGatsbyId.pageid === 'shipping'|| page.AcfGatsbyId.pageid === 'benefits'|| page.AcfGatsbyId.pageid === 'reviews' || page.AcfGatsbyId.pageid === 'order' || page.AcfGatsbyId.pageid === 'contact') ? <SvgSubBackStatic /> : <SvgSubBack />}

        {(page.AcfGatsbyId.pageid === 'benefits' || page.AcfGatsbyId.pageid === 'reviews' || page.AcfGatsbyId.pageid === 'about-us') &&
          <div className={``} >
            {page.featuredImage && 
            <Image
              className={'defaultBackgroundImg'}
              style={{ position: 'fixed', top: '125px', zIndex: '0', minHeight: '100vh' }}
              fluid={page.featuredImage.node.localFile.childImageSharp.fluid}
              alt={typeof page.featuredImage.node.altText !== 'undeined' ? page.featuredImage.node.altText : ''}
              title={typeof page.featuredImage.node.title !== 'undeined' ? page.featuredImage.node.title : false}
            />}
          </div>
        }

        {page.AcfGatsbyId.pageid === 'start' &&
          <div className={`pageHeroBackgroundEXT`} >
            <StartLayout page={page} />
          </div>
        }
        
        {(page.AcfGatsbyId.pageid === 'reviews' && orientation === 'desktop') &&
          <div className={`pageHeroBackgroundEXT`} >
            <img className={`pageHeroBackground`} src={ReviewsBackImg} alt="decoration" />
          </div>
        }

        {page.AcfGatsbyId.pageid === 'placing-order' &&
          <div className={`pageHeroBackgroundEXT`} >
            <img className={`pageHeroBackground`} src={OrderBackImg} alt="decoration" />
          </div>
        }

        {page.AcfGatsbyId.pageid === 'clients' &&
          <div className={`pageHeroBackgroundEXT`} >
            <ClientsBackgroundSlider />
          </div>
        }

      </LayoutPortalTop>


      {(page.AcfGatsbyId.pageid === 'start' && state.windowWidth > 1078) && <div
        className={`playHandler ${state.playStartBusiness === true ? 'videoPlay' : ''}`}
        style={{
          position: 'fixed',
          // top: `${state.windowWidth * 0.5625 / 2 - 125 }px`,
          // right: `${state.windowWidth / 3 - 220}px`
          top: `${state.windowMode === 'higher' ? `${((state.windowWidth  - 225) * 0.5625) / 2 }px` : `calc((100vh - 200px) / 2)`}`,

          // right: `${state.windowWidth / 3 - 220}px`,
          right: `${playHandlerRight}px`,

          zIndex: 10
        }}
      >
        <div className={`playHandlerInner`} onClick={hasPlayer} role="button" tabIndex="0" onKeyDown={hasPlayer}>
          <SvgPlay />
        </div>
      </div>}


      <main className="main" style={(page.AcfGatsbyId.pageid === 'start' && state.windowWidth > 1078) ? {maxWidth:'50vw'} : {}} >
      <div className={`container ${state.playStartBusiness === true ? 'videoPlay' : ''}`} >

          <div
            className={`placeholderForHeading`}
          >
          </div>

          {children}
        </div>
      </main>

      <LayoutPortalBottom 

        level={(state.playStartBusiness) ? 'bottom' : 'uberTop'} 
        // level={(state.playStartBusiness || state.menu) ? 'bottom' : 'uberTop'} 
        
        // position={`fixed`}
      >

        <Footer pageContext={pageContext} logo={logoBlack} />

        {/* <InterSectionTransition /> */}

        <WindowDriver />

      </LayoutPortalBottom>

      {/* <TransitionCamo /> */}

    </div>
  )
}

export default BusinessLayout